import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('crossBlocDataUpdatesField', crossBlocDataUpdatesFieldDirective)
       .directive('crossBlocDataUpdatesFieldPrint', crossBlocDataUpdatesFieldPrintDirective)
       .service('crossBlocDataUpdatesField', crossBlocDataUpdatesField)
    .directive('crossBlocDataUpdatesFieldBuild',crossBlocDataUpdatesFieldBuild);

crossBlocDataUpdatesFieldDirective.$inject = ['Configuration'];
/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function crossBlocDataUpdatesFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/cross-bloc-data-updates/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

crossBlocDataUpdatesFieldPrintDirective.$inject = ['Configuration'];
/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function crossBlocDataUpdatesFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/cross-bloc-data-updates/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function crossBlocDataUpdatesFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/cross-bloc-data-updates/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function crossBlocDataUpdatesField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {

            let initialParams = {
                'crossBlocDataStationToEdit': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select the bloc you would like to update',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'dataManipulationOption': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select your data manipulation option',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'whenActionHappens': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose when the action happens',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'crossBlocDataUpdatesReverseOrder': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairsToCheckAgainst': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairsArrayToCheckAgainst': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairsToUpdate': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairsArrayToUpdate': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldIdForQuantityOfRecords': {
                    'validation' : {
                        'required': false,
                    }
                },
                'actionButtonText': {
                    'validation' : {
                        'required': false,
                    }
                },
                'affectedEntryOption': {
                    'validation' : {
                        'required': false,
                    }
                },
                'useAutoGenerate': {
                    'validation' : {
                        'required': false,
                    }
                },
                'addRecordIfNoneExists' : {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairsArrayToAddNewIfNoRecord' : {
                    'validation' : {
                        'required': false
                    }
                },
                'localFieldMatchingFieldPairsToAddNewIfNoRecord' : {
                    'validation' : {
                        'required': false
                    }
                },
                'removeRecordIfValueBecomesZero' : {
                    'validation' : {
                        'required': false
                    }
                },
                'removeRecordIfValueIsBelowZero' : {
                    'validation' : {
                        'required': false
                    }
                },
            };

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}
