import * as angular from 'angular';

"use strict";

angular.module('GridModule')
       .service('GridSemaphore', GridSemaphore);

GridSemaphore.$inject = ['Configuration'];
/**
 * Object that will build the semaphore action of the grid object
 * this is created to be just a simple semaphore object.
 * @constructor
 */
function GridSemaphore(Configuration) {

    let service = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();

    service._isGridEnabled = true;
    service._gridSemaphoreURL = 'components/grid/template/grid-semaphore.html?v=' + version + 'rand=' + random;

    // Method that will turn on the grid
    service.gridSemaphoreURL = function () {
        return service._gridSemaphoreURL;
    }

    // Method that will turn on the grid
    service.gridOn = function () {
        service._isGridEnabled = true;
    }

    // Method that will turn off the grid
    service.gridOff = function () {
        service._isGridEnabled = false;
    }

    // Method that will return the status in a text format
    service.getGridStatusText = function () {
        return service.isGridEnabled() ? 'Enabled' : 'Disabled';
    }

    // Method that will return the status in a text format
    service.getGridButtonAction = function () {
        return service.isGridEnabled() ? 'gridOn' : 'gridOff';
    }

    /**
     * Method that will do the actions of the
     * semaphore click, that can be the opposite
     * status of the field _isGridEnabled.
     */
    service.click = function () {
        service._isGridEnabled
            ? service.gridOff()
            : service.gridOn();
    }

    // This will say if the grid is enabled
    service.isGridEnabled = function () {
        return service._isGridEnabled === true;
    }
}
