import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('GridDeleteRowController', GridDeleteRowController);

GridDeleteRowController.$inject = ['$scope','ScopeAndLocal'];

// Controller Constructor
function GridDeleteRowController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'grid');
            ScopeAndLocal.startVariable(vm, $scope, 'line');
            ScopeAndLocal.startVariable(vm, $scope, '$index');

        } catch (e) {
            console.log(e);
        }
    }

}

export default GridDeleteRowController;