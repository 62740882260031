import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('timeDifferenceField', timeDifferenceFieldDirective)
    .directive('timeDifferenceFieldPrint', timeDifferenceFieldPrintDirective)
    .service('timeDifferenceField', timeDifferenceField)
    .directive('timeDifferenceFieldBuild', timeDifferenceFieldBuild);

timeDifferenceFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function timeDifferenceFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/time-difference/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

timeDifferenceFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function timeDifferenceFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/time-difference/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function timeDifferenceField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'startAndFinishFields': {
                    'subParameters': {
                        'startFieldId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a starting field to compare.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'finishFieldId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a finishing field to compare.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose a starting and finishing field to compare.',
                        'requiredType': 'mustHaveAll'
                    }
                },
                'timeDateDifferenceFormat' : {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose a format for detecting the time or date value',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'timeDateDifferenceFormatForEndField' : {
                    'validation' : {
                        'required':false
                    }
                },
                'defaultResultFormat' : {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose a format for how you want the resulting difference to appear',
                        'requiredType': 'listMustHaveOne'
                    }
                }
            };


            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function timeDifferenceFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/time-difference/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

    