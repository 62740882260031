import * as angular from 'angular';
import * as echarts from 'echarts/index.blank'
import EChartOption = echarts.EChartsOption;
import $ from "jquery";

"use strict";

angular.module('FieldModule')
    .service('eChartPieAndLineCombination', eChartPieAndLineCombination);


eChartPieAndLineCombination.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function eChartPieAndLineCombination() {

    let service = this;

    /**
     * method to build the basic line chart
     *
     * @param fieldSlug
     */
    service.build = async function (fieldSlug, eChartData, genericOptions) {
        // based on prepared DOM, initialize echarts instance

        let echartDataSeriesLayout = echarts.init(document.getElementById('echart-' + fieldSlug));
        let seriesArray = [];

        let i = 0;
        let dataSetSource = [];

        dataSetSource[0] = ['item'];
        angular.forEach(eChartData.data.XAxisItems, function (legend) {
            dataSetSource[0].push(legend);
        });


        angular.forEach(eChartData.data.itemCountResults, function (itemToCountRow, legendKey) {

            dataSetSource[i + 1] = [legendKey];

            let dataArray = [];
            angular.forEach(itemToCountRow, function (countValue) {
                dataArray.push(countValue);
                dataSetSource[i + 1].push(countValue);
            });

            i++;
        });


        for (i = 0; i < eChartData.data.legendArray.length; i++) {
            seriesArray.push({
                type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: {focus: 'series'}
            });
        }

        seriesArray.push({
            type: 'pie',
            id: 'pie',
            radius: '30%',
            center: ['50%', '25%'],
            emphasis: {focus: 'data'},
            label: {
                formatter: '{b}: {@' + eChartData.data.XAxisItems + '} ({d}%)'
            },
            encode: {
                itemName: 'item',
                value: eChartData.data.XAxisItems,
                tooltip: eChartData.data.XAxisItems
            }
        })
        let defaultColour = $('.username-text > p').css("color");
        let options = {
            legend: {},
            dataset: {
                source: dataSetSource
            }, xAxis: {
                type: 'category', axisLabel: {
                    color: defaultColour
                },
                splitLine: {
                    lineStyle: {
                        opacity: .3
                    }
                }
            },
            yAxis: {
                gridIndex: 0,
                axisLabel: {
                    color: defaultColour
                },
                splitLine: {
                    lineStyle: {
                        opacity: .3
                    }
                }
            },
            grid: {top: '55%'},
            series: seriesArray
        };

        options = Object.assign(options, genericOptions);

        echartDataSeriesLayout.on('updateAxisPointer', function (event) {
            var xAxisInfo = event.axesInfo[0];
            if (xAxisInfo) {
                var dimension = xAxisInfo.value + 1;
                echartDataSeriesLayout.setOption({
                    series: {
                        id: 'pie',
                        label: {
                            formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                        },
                        encode: {
                            value: dimension,
                            tooltip: dimension
                        }
                    }
                });
            }
        });
        echartDataSeriesLayout.setOption(options);
    }
}

