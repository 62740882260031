import * as angular from 'angular';

'use strict';

angular.module('HelpModeModule')
       .service('HelpModeService', HelpModeService);

/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function HelpModeService() {
    let service = this;

}
