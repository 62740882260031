import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('dateTimeSelectorField', dateTimeSelectorFieldDirective)
    .directive('dateTimeSelectorFieldPrint', dateTimeSelectorFieldPrintDirective)
    .directive('dateField', dateField)
    .directive('timeField', timeField)
    .directive('datetimeField', datetimeField)
    .service('dateTimeSelectorField', dateTimeSelectorField)
    .directive('dateTimeFieldBuild', dateTimeFieldBuild)
;

dateField.$inject = ['Configuration'];

function dateField(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/date-time-selector/fields/date.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

timeField.$inject = ['Configuration'];

function timeField(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/date-time-selector/fields/time.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}


datetimeField.$inject = ['Configuration'];

function datetimeField(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/date-time-selector/fields/datetime.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

dateTimeSelectorFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dateTimeSelectorFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/date-time-selector/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

dateTimeSelectorFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dateTimeSelectorFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/date-time-selector/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function dateTimeSelectorField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'dateTimeSetting': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please choose a date or time setting for your field',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'numberOfDaysFieldId': {
                    'validation': {
                        'required': false,
                    }
                },
                'dateToAddFromFieldId': {
                    'validation': {
                        'required': false,
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}


/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dateTimeFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/date-time-selector/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
