import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('formListField', formListFieldDirective)
    .directive('formListFieldPrint', formListFieldPrintDirective)
    .service('formListField', formListField)
    .directive('formListFieldBuild', formListFieldBuild);

formListFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function formListFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/data-to-form/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

formListFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function formListFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/data-to-form/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function formListField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'formListJobAndStationId': {
                    'subParameters': {
                        'jobId': {
                            'validation': {
                                'required': true,
                                'validationResponse': 'Please choose a module you would like to load blocs from',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please choose a module you would like to load blocs from',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'formDisplayOption': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please choose how you would like the blocs to be displayed',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'linkedStationDetails': {
                    'validation': {
                        'required': false
                    }
                },
                'formListLocalRemoteFields': {
                    'validation': {
                        'required': false
                    }
                },
                'formListLocalRemoteFieldsArray': {
                    'validation': {
                        'required': false
                    }
                },
                'formListLatestDataSlugs': {
                    'validation': {
                        'required': false
                    }
                },
                'formListLatestDataSlugFilters': {
                    'validation': {
                        'required': false
                    }
                },
                'formListLatestDataSlugFiltersArray': {
                    'validation': {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function formListFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/data-to-form/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
