import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('autoGenerateField', autoGenerateFieldDirective)
    .directive('autoGenerateFieldPrint', autoGenerateFieldPrintDirective)
    .directive('autoGenerateFieldBuild', autoGenerateFieldBuild)

    .service('autoGenerateField', autoGenerateField);

autoGenerateFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function autoGenerateFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/auto-generate/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

autoGenerateFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function autoGenerateFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/auto-generate/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function autoGenerateField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function (fieldType) {
        try {
            let initialParams = {};

            if (fieldType == 'auto-generate-full-timestamp' || fieldType == 'auto-generate-time') {
                initialParams = {}
            }

            if (fieldType == 'auto-generate-full-timestamp') {
                initialParams = {
                    'dateFormat': {
                        'validation': {
                            'required': false
                        }
                    }
                };
            }

            if (fieldType == 'auto-generate-number') {
                initialParams = {
                    'digitLength': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a digital length for the auto generated number.',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'startingNumber': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a starting number for the auto generated number',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'referenceField': {
                        'validation': {
                            'required': false
                        }
                    }
                };
            }

            if (fieldType == 'auto-generate-number-text') {
                initialParams = {
                    'digitLength': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a digital length for the auto generated number.',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'startingNumber': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a starting number for the auto generated number',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'referenceField': {
                        'validation': {
                            'required': false
                        }
                    },
                    'autoGenerateStartingText': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please choose the starting text for your autogenerated text/number field',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                };
            }


            if (fieldType == 'auto-generate-number-date') {
                initialParams = {
                    'digitLength': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a digital length for the auto generated number.',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'startingNumber': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a starting number for the auto generated number',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'dateFormat': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please choose a date format for your autogenerated number/date field',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                };
            }

            if (fieldType == 'auto-generate-number-text-date') {
                initialParams = {
                    'digitLength': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a digital length for the auto generated number.',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'startingNumber': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please type a starting number for the auto generated number',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'dateFormat': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please choose a date format for your autogenerated number/date field',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'autoGenerateStartingText': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please choose the starting text for your autogenerated text/number field',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                };
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function autoGenerateFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/auto-generate/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

