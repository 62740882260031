import * as angular from 'angular';
import HelpModeController from "./controllers/helpmode.controller";

"use strict";

let HelpModeComponent =  {
    templateUrl: 'components/help-mode/template/help-mode.html',
    controller: HelpModeController,
    controllerAs: 'hm',
    bindings: {
        helpModePath: '<',
        locationType: '<'
    }
}

angular.module('HelpModeModule')
    .component('helpMode', HelpModeComponent);
