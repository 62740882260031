import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('fieldMergeBuilderField', fieldMergeBuilderFieldDirective)
    .directive('fieldMergeBuilderFieldPrint', fieldMergeBuilderFieldPrintDirective)
    .directive('fieldMergeBuilderBuild', fieldMergeBuilderBuild)
    .service('fieldMergeBuilderField', fieldMergeBuilderField);

fieldMergeBuilderFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function fieldMergeBuilderFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/field-merge-builder/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

fieldMergeBuilderFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function fieldMergeBuilderFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/field-merge-builder/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function fieldMergeBuilderField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'fieldMergeFormat': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please enter a format for your field merge',
                        'requiredType': 'listMustHaveOne'
                    }
                },
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function fieldMergeBuilderBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/field-merge-builder/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
