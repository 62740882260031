import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('formception2Field', formception2FieldDirective)
    .directive('formception2FieldPrint', formception2FieldPrintDirective)
    .service('formception2Field', formception2Field)
    .directive('formceptionTwoFieldBuild', formceptionTwoFieldBuild);

formception2FieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function formception2FieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/formception-2/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

formception2FieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function formception2FieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/formception-2/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function formception2Field() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'triggerSubBlocOption': {
                    'validation' : {
                        'required': false,
                    }
                },
                'formCeptionPopUpOrEmbed': {
                    'validation' : {
                        'required': false,
                    }
                },
                'formceptionTwoStationAndJob': {
                    'subParameters': {
                        'stationId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a Bloc you would like to load',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'jobId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a module in order to load a Bloc',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please make sure you have a Bloc and Module loaded for this field',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'subFormParentChildFieldsArray': {
                    'validation' : {
                        'required': false
                    }
                },
                'subFormSendChildDataToParentFieldsArray': {
                    'validation' : {
                        'required': false
                    }
                },
                'clickShowMoreLastEntry' : {
                    'validation' : {
                        'required': false
                    }
                },
                'closeTheSubBlocOnly' : {
                    'validation' : {
                        'required': false
                    }
                },
                'closeTheSubBlocAndSubmitParentForm' : {
                    'validation' : {
                        'required': false
                    }
                },
                'closeSubBlocAndReOpenAnotherSubBlocAfterSubmit' : {
                    'validation' : {
                        'required': false
                    }
                },
                'closeSubBlocAndReOpenAnotherSubBlocUsingSeperateButton' : {
                    'validation' : {
                        'required': false
                    }
                },
                'closeSubBlocAndReOpenFieldId' : {
                    'validation' : {
                        'required': false
                    }
                },
                'hideCloseButton' : {
                    'validation' : {
                        'required': false
                    }
                },
                'seperateButtonText' : {
                    'validation' : {
                        'required': false
                    }
                },
                'compactView' : {
                    'validation' : {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function formceptionTwoFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/formception-2/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
    