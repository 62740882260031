import * as angular from 'angular';

"use strict";

angular.module('FilterModule')
       .service('FilterByFieldService', FilterByFieldService);

/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function FilterByFieldService() {

    let service = this;

    /**
     * Service that will check if a filed_id contains a filter
     * that says this field its also a filter by.
     *
     * @param field_id id of the field
     * @param fieldObject filter object
     * @returns {boolean} true if the object contains:
     * 1 - same filter id from the list of scan station fields
     * 2 - contains the filterByField on as a parameter
     * 3 - contain that value == 1 (true)
     *
     * Otherwise will return false.
     */
    service.isFieldAsFilter = function (field_id, fieldObject) {
        try
        {
            let isFieldAFilter = false;
            let parameters = fieldObject.parameters;

            angular.forEach(parameters, function (param) {
                if (param.scan_station_field_id == field_id && (param.scan_station_field_parameter == "filterByField" || param.scan_station_field_parameter == "filterByFieldSubBloc" || param.scan_station_field_parameter == "filterValueOnlyField")
                    && param.parameter_value == 1) {
                    isFieldAFilter = true;
                }
            });
            return isFieldAFilter;

        } catch (e) {
            console.log(e);
        }

    }

}
