import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('blocworxUserPasswordField', blocworxUserPasswordFieldDirective)
       .directive('blocworxUserPasswordFieldPrint', blocworxUserPasswordFieldPrintDirective)
       .service('blocworxUserPasswordField', blocworxUserPasswordField);

blocworxUserPasswordFieldDirective.$inject = ['Configuration'];
/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUserPasswordFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-password/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

blocworxUserPasswordFieldPrintDirective.$inject = ['Configuration'];
/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUserPasswordFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-password/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function blocworxUserPasswordField() {
    let service = this;
}
