import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('wysiwygForUserFieldField', wysiwygForUserFieldFieldDirective)
    .directive('wysiwygForUserFieldFieldPrint', wysiwygForUserFieldFieldPrintDirective)
    .directive('wysiwygForUserFieldBuild', wysiwygForUserFieldBuild)
    .service('wysiwygForUserFieldField', wysiwygForUserFieldField);

wysiwygForUserFieldFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function wysiwygForUserFieldFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/wysiwyg-for-user/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

wysiwygForUserFieldFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function wysiwygForUserFieldFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/wysiwyg-for-user/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function wysiwygForUserFieldField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'basicEditor' : {
                    'validation': {
                        'required': false,
                    }
                },
                'openEditorText': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please choose a default text for the button that opens the editor, e.g. "Open Editor"',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'showEditorByDefault' : {
                    'validation': {
                        'required': false,
                    }
                },
                'hideEditButton' : {
                    'validation': {
                        'required': false,
                    }
                },
                'hideContentDisplay' : {
                    'validation': {
                        'required': false,
                    }
                }
            }
            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}


/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function wysiwygForUserFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/wysiwyg-for-user/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}