import * as angular from 'angular';
import './echart-option/basicLine.service'
import './echart-option/smoothedLine.service'
import './echart-option/gradientStackedArea.service'
import './echart-option/basicArea.service'
import './echart-option/stackedLine.service'
import './echart-option/stackedArea.service'
import './echart-option/valueBasedColouredLine.service'
import './echart-option/pieAndLineCombination.service'
import './echart-option/basicBar.service'
import './echart-option/stackedColumn.service'
import './echart-option/stackedHorizontalBarColumn.service'
import './echart-option/basicPie.service'
import './echart-option/doughnut.service'
import $ from 'jquery';

"use strict";

angular.module('FieldModule')
    .directive('eChartField', eChartFieldDirective)
    .directive('eChartFieldBuild', eChartFieldBuild)
    .service('EChartField', EChartField);

eChartFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function eChartFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/echart/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

eChartFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function eChartFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/echart/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

EChartField.$inject = [
    'eChartBasicLine',
    'eChartSmoothedLine',
    'eChartBasicArea',
    'eChartStackedLine',
    'eChartStackedArea',
    'eChartGradientStackedArea',
    'eChartValueBasedColouredLine',
    'eChartPieAndLineCombination',
    'eChartBasicBar',
    'eChartStackedColumn',
    'eChartStackedHorizontalBar',
    'eChartBasicPie',
    'eChartDoughnut',
    'EChartFactory'
];

/**
 * Service constructor.
 * @constructor
 */
function EChartField(
    eChartBasicLine,
    eChartSmoothedLine,
    eChartBasicArea,
    eChartStackedLine,
    eChartStackedArea,
    eChartGradientStackedArea,
    eChartValueBasedColouredLine,
    eChartPieAndLineCombination,
    eChartBasicBar,
    eChartStackedColumn,
    eChartStackedHorizontalBar,
    eChartBasicPie,
    eChartDoughnut,
    EChartFactory
) {

    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'eChartOption': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select a chart you wish to generate',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'eChartHeight': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose the height of your chart',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'eChartWidth': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose the width of your chart',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'customEChartOptions': {
                    'validation' : {
                        'required': false
                    }
                },
                'refreshDataButtonText': {
                    'validation' : {
                        'required': false
                    }
                },
                'hideButton': {
                    'validation' : {
                        'required': false
                    }
                },
                'XAxisDateOrValues': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose your X Axis option',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'XAxisDateDetails': {
                    'validation' : {
                        'required': false
                    },
                    'subParameters': {
                        'breakdown': {
                            'validation' : {
                                'required': false
                            }
                        },
                        'fromBeginning': {
                            'validation' : {
                                'required': false
                            }
                        },
                        'toPresent': {
                            'validation' : {
                                'required': false
                            }
                        },
                        'localToDateFieldId': {
                            'validation' : {
                                'required': false
                            }
                        },
                    },
                },
                'XAxisLocalFieldsArray': {
                    'validation': {
                        'required': false
                    }
                },
                'XAxisPermanentValuesArray': {
                    'validation': {
                        'required': false
                    }
                },
                'searchesByUserNames': {
                    'validation': {
                        'required': false
                    }
                },
                'searchesByAllFieldValueVariations': {
                    'validation': {
                        'required': false
                    }
                },
                'XAxisDateOrValuesStationId': {
                    'validation': {
                        'required': false
                    }
                },
                'valuesFromAnotherBlocFieldId': {
                    'validation': {
                        'required': false
                    }
                },
                'countLineItemsArray': {
                    'validation': {
						'required': true,
						'validationResponse': 'Please add at least one count item',
						'requiredType': 'listMustHaveOne'
                    }					

                },




            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * Main construction of the echart, this contains behaviour
     * to show the echart with labels
     *
     * @param fieldSlug
     * @param fieldId
     * @param allFieldsData
     * @param chartOption
     * @param customEChartOptions

     * @param chartOption
     */
    service.buildEChart = async function (fieldSlug, fieldId, allFieldsData, chartOption, customEChartOptions) {
        let eChartData = await EChartFactory.getEChartData(fieldId, allFieldsData);
        let defaultColour = $('.username-text > p').css("color");
        let genericOptions = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {},
                    dataView: {
                        show: true
                    }
                },
                top: 18,
                iconStyle: {
                    color: defaultColour
                }
            },
            dataView: { readOnly: true },
            legend: {
                textStyle: {
                    color: defaultColour
                }
                ,type: 'scroll'
            },
            dataZoom: [{
                type: 'inside',
                start: 0,
                end: 100
            }, {
                start: 0,
                end: 100
            }],
        };


        switch (chartOption) {
            case 'basic-line':
                await eChartBasicLine.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'smoothed-line':
                await eChartSmoothedLine.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'basic-area':
                await eChartBasicArea.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'basic-bar':
                await eChartBasicBar.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'stacked-line':
                await eChartStackedLine.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'stacked-column':
                await eChartStackedColumn.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'stacked-horizontal-bar':
                await eChartStackedHorizontalBar.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'stacked-area':
                await eChartStackedArea.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'basic-pie':
                await eChartBasicPie.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'doughnut':
                await eChartDoughnut.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'gradient-stacked-area':
                await eChartGradientStackedArea.build(fieldSlug, eChartData, genericOptions);
                break;
            case 'value-based-coloured-line':
                await eChartValueBasedColouredLine.build(fieldSlug, eChartData, genericOptions, customEChartOptions);
                break;
            case 'pie-and-line-combination':
                await eChartPieAndLineCombination.build(fieldSlug, eChartData, genericOptions);
                break;
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function eChartFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/echart/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
    