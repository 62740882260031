import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('blocworxUserEmailField', blocworxUserEmailFieldDirective)
       .directive('blocworxUserEmailFieldPrint', blocworxUserEmailFieldPrintDirective)
       .service('blocworxUserEmailField', blocworxUserEmailField);


blocworxUserEmailFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUserEmailFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-email/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

blocworxUserEmailFieldPrintDirective.$inject = ['Configuration'];
/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUserEmailFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-email/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function blocworxUserEmailField() {
    let service = this;
}

