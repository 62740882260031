import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('totalResultsField', totalResultsFieldDirective)
    .directive('totalResultsFieldPrint', totalResultsFieldPrintDirective)
    .directive('totalResultsFieldBuild', totalResultsFieldBuild)
    .service('TotalResultsField', TotalResultsField);

totalResultsFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function totalResultsFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/total-results/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

totalResultsFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function totalResultsFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/total-results/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function TotalResultsField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'totalResultsAssociatedFields': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please choose parameters for the total results field',
                        'requiredType': 'stringMustNotBeEmpty'
                    },					
                    'subParameters': {
                        'keyFieldId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose the field that contains the value you would like to look up.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'fieldIdToCount': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose the field that you would like to look up.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    }
                }
            };

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * Main validation of this field.
     *
     * @param field
     * @param scanStation
     * @param row
     * @returns {boolean}
     */
    service.validate = function (field, row, scanStation) {
        if (field.result_character_count !== null) {
            return false;
        }

        if (scanStation.checkIfEntryIsFormceptionEntry(field.field_slug) != false) {
            return false;
        }

        if (row[field.field_slug] == 'N/A') {
            return true;
        }

        return field.field_type != 'file' && field.field_type != 'digital-signature' && field.field_type != 'image-url-field';

    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function totalResultsFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/total-results/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
