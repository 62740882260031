import * as angular from 'angular';
import FieldController from "components/field/controllers/field.controller";

"use strict";

angular.module('FieldModule')
    .directive('checkboxField', checkboxFieldDirective)
    .directive('checkboxFieldPrint', checkboxFieldPrintDirective)
    .directive('checkboxFieldBuild', checkboxFieldBuild)
    .service('checkboxField', checkboxField);

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function checkboxFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/checkbox/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function checkboxFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/checkbox/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function checkboxField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'pressedUnpressedValues': {
                    'subParameters': {
                        'pressed': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please provide a value for when the checkbox is ticked',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'unPressed': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please provide a value for when the checkbox is unticked',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }

                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please provide values for the checked and unchecked options',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'checkboxText': {
                    'validation' : {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function checkboxFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/checkbox/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}


let checkboxFieldInDataEditComponent =  {
    templateUrl: 'components/field/template/checkbox/in-data-edit.html',
    controller: FieldController,
    controllerAs: 'fc',
    bindings: {
        row: '=',
        field: '=',
        scanStation: '='
    }
}

angular.module('FieldModule')
    .component('checkBoxFieldInDataEdit', checkboxFieldInDataEditComponent);