import * as angular from 'angular';
import * as echarts from 'echarts/index.blank'
import EChartOption = echarts.EChartsOption;
import $ from "jquery";

"use strict";

angular.module('FieldModule')
    .service('eChartBasicBar', eChartBasicBar);



eChartBasicBar.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function eChartBasicBar() {

    let service = this;

    /**
     * method to build the basic line chart
     *
     * @param fieldSlug
     */
    service.build = async function (fieldSlug, eChartData, genericOptions) {

        let seriesArray = [];

        angular.forEach(eChartData.data.itemCountResults,function(itemToCountRow, legendKey) {
            let dataArray = [];
            angular.forEach(itemToCountRow, function(countValue) {
                dataArray.push(countValue);
            });
            seriesArray.push( {
                data: dataArray,
                type: 'bar',
                name: legendKey
            })
        });

        // based on prepared DOM, initialize echarts instance
        let echartDataSeriesLayout = echarts.init(document.getElementById('echart-' + fieldSlug));
        let defaultColour = $('.username-text > p').css("color");
        let options  = {
            xAxis: {
                type: 'category',
                data: eChartData.data.XAxisItems,
                axisLabel: {
                    color: defaultColour
                },
                splitLine: {
                    lineStyle: {
                        opacity: .3
                    }
                }
            },
            legend: {
                data: eChartData.data.legendArray
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: defaultColour
                },
                splitLine: {
                    lineStyle: {
                        opacity: .3
                    }
                }
            },
            series: seriesArray
        };

        options = Object.assign(options, genericOptions);

        echartDataSeriesLayout.setOption(options);
    }
}

