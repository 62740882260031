import * as angular from 'angular';

"use strict";

angular.module('HelpModeModule')
       .controller('HelpModeController', HelpModeController);

// Injection of each class
HelpModeController.$inject = ['HelpModeFactory', 'Configuration', '$scope'];

// Controller Constructor
function HelpModeController(HelpModeFactory, Configuration, $scope) {
    // Creating the view model reference
    let vm = this;
    vm.helpModePath = '';
    vm.gitBookBlocworxDocumentationHostUrl = 'https://manual.blocworx.com/blocworx-documentation/';

    vm.$onInit = async function () {
        try {
            $scope.helpModePath = vm.helpModePath;

        } catch (e) {
            console.log(e);
        }
    }

    // function that calls the Git Book API
    // NB: This function is currently not in use
    vm.getHelpFromGitBook = function() {
        vm.helpModeResponse = HelpModeFactory.getHelpFromGitBooks();
    }

    vm.version = Configuration.getVersion();
    vm.randomValue = Configuration.getRandomValue();


}


export default HelpModeController;