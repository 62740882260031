import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('multiFilesField', multiFilesFieldDirective)
    .directive('multiFilesFieldPrint', multiFilesFieldPrintDirective)
    .directive('multiFilesBuild', multiFilesBuild)
    .service('multiFilesField', multiFilesField);

multiFilesFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function multiFilesFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/multifiles/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

multiFilesFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function multiFilesFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/multifiles/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function multiFilesField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {}

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function multiFilesBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/multifiles/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}