import * as angular from 'angular';

import LanguagesController from "./controllers/languages.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class LanguagesComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/languages';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = LanguagesController;

    // This will set an alias
    public controllerAs: string = 'lc';
}

angular.module('LanguagesModule').component('blocworxLanguages', <StandardComponent> new LanguagesComponent);