import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('radioButtonsFromStationField', radioButtonsFromStationField);

/**
 * Service constructor.
 * @constructor
 */
function radioButtonsFromStationField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'dropdownFromStationLookUp': {
                    'subParameters': {
                        'stationId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a bloc to look up.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'fieldIdToLookUp': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Select the remote field that will be searched against.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select a bloc and a field to look up',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'dropDownFromStationExcludeMatchingDataFromAnotherFieldData': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairs': {
                    'validation' : {
                        'required': false,
                    }
                },
                'dropdownFromStationReverseOrder': {
                    'validation' : {
                        'required': false,
                    }
                }
            };

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}