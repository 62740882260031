import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('labelField', labelFieldDirective)
    .service('labelField', labelField)
    .directive('labelFieldBuild', labelFieldBuild)
;

labelFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function labelFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/label/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function labelField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'labelText': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please type the label text',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function labelFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/label/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
