import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";

import GridScanStationController from "./controllers/grid.controller";

"use strict";

class GridModuleComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/grid';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        fc: '<',
        scanStation: '<',
        main: '<',
        data: '<',
        field: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = GridScanStationController;

    // This will set an alias
    public controllerAs : string = 'grid';
}

angular.module('FieldModule').component('gridModule', <StandardComponent>new GridModuleComponent);


let gridModuleEdit = {
    templateUrl: 'components/grid/template/grid-scan-station-edit.html',
    controller: GridScanStationController,
    controllerAs: 'grid',
    bindings: {
        scanStation: '=',
        fc: '=',
        main: '='
    }
}

angular.module('GridModule')
    .component('gridModuleEdit', gridModuleEdit);
