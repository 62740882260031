import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('resultsBoxField', resultsBoxFieldDirective)
    .directive('resultsBoxFieldPrint', resultsBoxFieldPrintDirective)
    .service('resultsBoxField', resultsBoxField)
    .directive('resultsBoxFieldBuild', resultsBoxFieldBuild);

resultsBoxFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function resultsBoxFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/results-box/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

resultsBoxFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function resultsBoxFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/results-box/print.html?v=' + version + 'rand=' + random;

    return {
        controller: "FieldController",
        controllerAs: 'fc',
        restrict: "A",
        templateUrl: templateUrl,
        link : (scope, element, attrs) => {

            element.bind("keydown keypress", function (event)
            {
                if(event.which === 27)
                {
                    let closeButton = angular.element(document.querySelectorAll(".close-modal"))
                    closeButton.triggerHandler('click')
                    event.preventDefault();
                }
            });
        }
    };

}

/**
 * Service constructor.
 * @constructor
 */
function resultsBoxField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'resultsBoxStationAndFields': {
                    'subParameters': {
                        'stationId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a Bloc to look up',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'localFieldId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a local field used to look up data.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'remoteFieldId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Select the remote field that will be searched against.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }

                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select a bloc and a field to look up',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'dropdownFromStationReverseOrder': {
                    'validation' : {
                        'required': false
                    }
                },
                'onlyShowFieldArray' : {
                    'validation' : {
                        'required': false
                    }
                },
                'valueBasedCellColours': {
                    'validation' : {
                        'required': false
                    }
                }
            };


            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * This function will be responsible to build the list of fields that we are only showing
     *
     * @param onlyShowFieldArray
     * @param field
     */
    service.addNewResultsBoxOnlyShowField = (onlyShowFieldArray, fieldId = null) => {

        if(fieldId == null) {
            alert('Please Select a Field');
            return onlyShowFieldArray;
        }


        if(typeof onlyShowFieldArray == 'undefined' || (typeof onlyShowFieldArray != 'undefined' && onlyShowFieldArray.length == 0) || (typeof onlyShowFieldArray === 'object' && Object.keys(onlyShowFieldArray).length == 0)) {
            onlyShowFieldArray = [];
        }

        // rebuild to an array if its an object
        if(typeof onlyShowFieldArray === 'object' && Object.keys(onlyShowFieldArray).length > 0) {
            let temporaryArray = [];

            angular.forEach(onlyShowFieldArray, function(value) {
                temporaryArray.push(value);
            })

            onlyShowFieldArray = temporaryArray;
        }

        onlyShowFieldArray.push(fieldId);

        return onlyShowFieldArray;
    }

    /**
     * This function will be responsible for the building the colour/value combination
     *
     * @param valueBasedCellColours
     * @param value
     * @param colour
     */
    service.addValueColourCombination = (valueBasedCellColours, value, colour) => {

        if(value == null || colour == null) {
            alert('Please choose a value/colour combination');
            return valueBasedCellColours;
        }


        if(typeof valueBasedCellColours == 'undefined' || (typeof valueBasedCellColours != 'undefined' && valueBasedCellColours.length == 0) || (typeof valueBasedCellColours === 'object' && Object.keys(valueBasedCellColours).length == 0)) {
            valueBasedCellColours = [];
        }

        // rebuild to an array if its an object
        if(typeof valueBasedCellColours === 'object' && Object.keys(valueBasedCellColours).length > 0) {
            let temporaryArray = [];

            angular.forEach(valueBasedCellColours, function(valueColourCombination) {
                temporaryArray.push(valueColourCombination);
            })

            valueBasedCellColours = temporaryArray;
        }

        valueBasedCellColours.push({
            'value': value,
            'colour': colour
        });

        return valueBasedCellColours;
    }

    /**
     * This will be responsible to remove value colour combinations
     *
     * @param valueBasedCellColours
     * @param index
     */
    service.removeValueColourCombination = (valueBasedCellColours, index = null) => {


        delete valueBasedCellColours[index];

        // build new object with fresh keys to keep it in sync and have all indexes in order (1,2,3,4,5,6 etc)
        let valueColourBasedListForLoop = valueBasedCellColours;

        valueBasedCellColours = [];

        let i = 0;

        angular.forEach(valueColourBasedListForLoop, function(value) {
            valueBasedCellColours[i] = value;
            i++;
        });

        return valueBasedCellColours;
    }

    /**
     * This will be responsible to remove from the list of fields that we are only showing
     *
     * @param onlyShowFieldArray
     * @param index
     */
    service.removeResultsBoxOnlyShowField = (onlyShowFieldArray, index = null) => {

        if(index == null) {
            alert('Please Select a field');
            return onlyShowFieldArray;
        }

        delete onlyShowFieldArray[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = onlyShowFieldArray;
        onlyShowFieldArray = [];
        let i = 0;
        angular.forEach(fieldListForLoop, function(value) {
            onlyShowFieldArray[i] = value;
            i++;
        });
        return onlyShowFieldArray;
    }

}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function resultsBoxFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/results-box/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

