import * as angular from 'angular';

'use strict';

angular.module('IconModule')
       .factory('IconFactory', IconFactory)
       .config(IconConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function IconConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

IconFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Icon Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {IconFactory}
 * @constructor
 */
function IconFactory($http, Configuration) {

    let factory = this;

    /**
     * This is the main function to get the list of main link icons
     * @returns {*}
     */
    factory.listIconFileNames = function () {

        let apiBase = Configuration.getApiUrl() + 'list-icon-file-names';
        let data = {};
        return $http.get(apiBase, data);
    }

    /**
     * This will update a scan station icon
     */
    factory.updateIcon = function (scanStationId, newIcon) {

        let apiBase = Configuration.getApiUrl() + 'update-icon';
        let body = {
            scanStationId: scanStationId,
            newIcon: newIcon
        };
        return $http.post(apiBase, body);
    }

    return factory;
}
