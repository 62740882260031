import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import GridDeleteRowController from "components/grid/components/grid-delete-row/controllers/gridDeleteRow.controller";

"use strict";

class GridDeleteRow extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/grid/components/grid-delete-row';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        grid: '=',
        line: '=',
        $index: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = GridDeleteRowController;

    // This will set an alias
    public controllerAs: string = 'gdc';
}

angular.module('GridModule').component('gridDeleteRow', <StandardComponent> new GridDeleteRow);

