import * as angular from 'angular';

'use strict';

angular.module('IconModule')
       .service('IconService', IconService);

/**
 * This will be responsible to implement filters on the
 * application.
 *
 * @constructor
 */
function IconService() {
    let service = this;

}
