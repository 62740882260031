import * as angular from 'angular';

"use strict";

angular.module('FormModule')
       .factory('FormFactory', FormFactory);

FormFactory.$inject = [];

/**
 *
 * @constructor
 */
function FormFactory() {

    let factory = this;
    let submited = false;
    factory.verbose = true;

    factory.send = async (functionCall = undefined, params) => {
        if (functionCall !== undefined && submited === false) {
            try {
                submited = true;
                // !factory.verbose ? factory.verbose = false : console.log('send');
                await functionCall(...params);
            } finally {
                submited = false;
                // !factory.verbose ? factory.verbose = false : console.log('sent');
            }
        } else {
            // !factory.verbose ? factory.verbose = false : console.log('X');
        }
    }

    return factory;
}

export default FormFactory;