import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('bartenderFileGeneratorField', bartenderFileGeneratorFieldDirective)
    .directive('bartenderFileGeneratorFieldPrint', bartenderFileGeneratorFieldPrintDirective)
    .service('bartenderFileGeneratorField', bartenderFileGeneratorField)
    .directive('bartenderFileGeneratorFieldBuild', bartenderFileGeneratorFieldBuild);

bartenderFileGeneratorFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function bartenderFileGeneratorFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/bartender-file-generator/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

bartenderFileGeneratorFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function bartenderFileGeneratorFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/bartender-file-generator/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function bartenderFileGeneratorField() {
    let service = this;
    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'sftpOrBrowser': {
                    'validation': {
                        'required': false,
                    }
                },
                'bartenderSftpUserName': {
                    'validation': {
                        'required': false,
                    }
                },
                'bartenderFileName': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please type the file name including extension that the integrator will detect.',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'bartenderFieldSlugs': {
                    'validation': {
                        'required': false
                    }
                }
            };

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function bartenderFileGeneratorFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/bartender-file-generator/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}