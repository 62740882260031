import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('blocworxUsernameField', blocworxUsernameFieldDirective)
       .directive('blocworxUsernameFieldPrint', blocworxUsernameFieldPrintDirective)
       .service('blocworxUsernameField', blocworxUsernameField);

blocworxUsernameFieldDirective.$inject = ['Configuration'];
/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUsernameFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-name/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

blocworxUsernameFieldPrintDirective.$inject = ['Configuration'];
/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUsernameFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-name/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function blocworxUsernameField() {
    let service = this;
}
