import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('blocworxUserButtonField', blocworxUserButtonFieldDirective)
       .directive('blocworxUserButtonFieldPrint', blocworxUserButtonFieldPrintDirective)
       .service('blocworxUserButtonField', blocworxUserButtonField);

blocworxUserButtonFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUserButtonFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-button/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

blocworxUserButtonFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function blocworxUserButtonFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/blocworx-user-button/print.html?v='  + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function blocworxUserButtonField() {
    let service = this;
}
