import * as angular from 'angular';
import IconController from "./controllers/icon.controller";

"use strict";

let IconPopupComponent =  {
    templateUrl: 'components/icon/template/icon-popup.html',
    controller: IconController,
    controllerAs: 'icon',
    bindings: {
        data: '<',
        main: '<',
        page: '<',
    }
}

angular.module('IconModule')
       .component('iconPopup', IconPopupComponent);


let IconFormComponent =  {
   templateUrl: 'components/icon/template/icon-form.html',
   controller: IconController,
   controllerAs: 'icon',
   bindings: {
       data: '<',
       scanStation: '<',
   }
}

angular.module('IconModule')
      .component('iconForm', IconFormComponent);
