import * as angular from 'angular';
import * as echarts from 'echarts/index.blank'
import EChartOption = echarts.EChartsOption;
import $ from "jquery";

"use strict";

angular.module('FieldModule')
    .service('eChartValueBasedColouredLine', eChartValueBasedColouredLine);


eChartValueBasedColouredLine.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function eChartValueBasedColouredLine() {

    let service = this;

    /**
     * method to build the basic line chart
     *
     * @param fieldSlug
     */
    service.build = async function (fieldSlug, eChartData, genericOptions, customEChartOptions) {
        // based on prepared DOM, initialize echarts instance

        let echartDataSeriesLayout = echarts.init(document.getElementById('echart-' + fieldSlug));
        let seriesArray = [];
        let i = 0;

        let highestValue = 0;
        angular.forEach(eChartData.data.itemCountResults, function (itemToCountRow, legendKey) {
            angular.forEach(itemToCountRow, function (countValue) {
                if (countValue > highestValue) {
                    highestValue = countValue;
                }
            });
        });

        let highestValueQuarter = Math.round(highestValue / 4);
        let valueSplitArray = [];
        let colours = [
            '#93CE07', '#FBDB0F', '#FC7D02', '#FD0100', '#AC3B2A'
        ];

        if(customEChartOptions.colourOrder == 'green-is-high') {
            colours = colours.reverse();
        }
        let index = 0;
        for (i = 0; i <= highestValue; i = i + highestValueQuarter) {
            valueSplitArray.push(
                [i-highestValueQuarter, i, colours[index]]
            )
            index++;
        }
        let visualMapData = [];

        angular.forEach(eChartData.data.itemCountResults, function (itemToCountRow, legendKey) {
            let dataArray = [];
            angular.forEach(itemToCountRow, function (countValue) {
                dataArray.push(countValue);
            });
            let markLineData = [];

            angular.forEach(valueSplitArray, function(data) {
                markLineData.push(
                    {
                        yAxis: data[1]
                    }
                );

                visualMapData.push(
                    {
                        gt: data[0],
                        lte: data[1],
                        color: data[2]
                    }
                );
            });

            markLineData.push(
                {
                    yAxis: highestValueQuarter
                }
            );

            delete visualMapData[visualMapData.length-1].lte;

            seriesArray.push({
                data: dataArray,
                type: 'line',
                name: legendKey,
                smooth: true,
                markLine: {
                    silent: true,
                    lineStyle: {
                        color: '#333'
                    },
                    data: markLineData
                }
            });
            i++;
        });
        let defaultColour = $('.username-text > p').css("color");
        let options = {
            legend: {
                data: eChartData.data.legendArray
            },
            grid: {
                left: '5%',
                right: '15%',
                bottom: '10%'
            },
            xAxis: [
                {
                    data: eChartData.data.XAxisItems,
                    axisLabel: {
                        color: defaultColour
                    },
                    splitLine: {
                        lineStyle: {
                            opacity: .3
                        }
                    }
                }
            ],
            yAxis: {          axisLabel: {
                    color: defaultColour
                },
                splitLine: {
                    lineStyle: {
                        opacity: .3
                    }
                }},
            visualMap: {
                top: 50,
                right: 10,
                pieces: visualMapData,
                outOfRange: {
                    color: '#999'
                }
            },
            series: seriesArray
        };

        options = Object.assign(options, genericOptions);


        echartDataSeriesLayout.setOption(options);
    }
}

