import * as angular from 'angular';
// Injection of each class
import Grid from "echarts/types/src/coord/cartesian/Grid";

"use strict";

angular.module('GridModule')
    .controller('gridScanStationController', GridScanStationController);

GridScanStationController.$inject = ['GridFactory', 'UnknownExceptionFactory', 'ArgumentUndefinedExceptionFactory',
    'Configuration', '$scope', '$rootScope', 'scanStation','ScopeAndLocal', 'ScanStationFactory'];

// Controller Constructor
function GridScanStationController(GridFactory, UnknownExceptionFactory, ArgumentUndefinedExceptionFactory,
                                   Configuration, $scope, $rootScope, scanStation, ScopeAndLocal, ScanStationFactory) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = function () {
        try
        {
            vm.validate = false;
            const version = vm.version = Configuration.getVersion();
            const random = Configuration.getRandomValue();

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            $scope.data = vm.data;

            ScopeAndLocal.startVariable(vm, $scope, 'field');
            $scope.field = vm.field;

            vm.lines = [];
            vm.refreshGrid = false;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will load once that we build the scan station edit.
     */
    vm.broadcastGridData = () => {
        $scope.$on('reloadSpecificGrids', async function (event, gridIdsToUpdate) {
            if(vm.refreshGrid == false){
                vm.refreshGrid = true;
                await vm.reloadSpecificGrids(gridIdsToUpdate);
                vm.refreshGrid = false;
            }
        });
    }

    /**
     * This will load the field for a grid element.
     *
     * @param field_id
     * @param gridfield
     */


    /**
     * Method that checks if a grid should be
     * enabled or disabled
     * @returns {boolean} true: enables | false : disabled
     */
    vm.isGridEnabled = function () {
        try {
            return GridFactory.isGridEnabled()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will return the status in a text format
     * @returns {string|string}
     */
    vm.gridStatusText = function () {
        try {
            return GridFactory.getGridStatusText()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will return the status in a text format
     * @returns {string|string}
     */
    vm.getGridButtonAction = function () {
        try {
            return GridFactory.getGridButtonAction()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will return the status in a text format
     * @returns {*}
     */
    vm.semaphoreClick = function () {
        try {
            return GridFactory.semaphoreClick()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Removing the quantity from the child count
     * @param child
     * @returns {*}
     */
    vm.removeQuantity = function (child) {
        try {
            return GridFactory.removeQuantity(child)
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will do the validation of scan station form validate
     */
    vm.scanStationFormValidate = function (field) {
        try {
            let validate = GridFactory.scanStationFormValidate(vm.scanStation, field);
            if(validate != null){
                vm.validate = validate;
            }
            return vm.validate;
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Function that will check if the current user is valid to show
     * elements.
     * @returns {*}
     */
    vm.isValidUser = function (main) {
        try {
            return GridFactory.isValidUser(main);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Function responsible for the click action of the semaphore.
     * @returns {*}
     */
    vm.semaphoreClick = function () {
        try {
            return GridFactory.semaphoreClick();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Function responsible for the click action of the semaphore.
     * @returns {*}
     */
    vm.gridSemaphoreURL = function () {
        try {
            return GridFactory.gridSemaphoreURL();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will connect the Scan Station Fields data but only for specific grids
     * @param scanStation
     */

    vm.reloadSpecificGrids = async function (gridIdsToUpdate) {
        try
        {
            // loading the scanStationFields for this screen
            if (typeof vm.scanStation.scanStationObj != 'undefined') {

                let response = await GridFactory.getSpecificGridLines(vm.scanStation.id, gridIdsToUpdate);
                angular.forEach(vm.lines, function(line, lineKey) {
                    if (typeof line['children'] != 'undefined') {
                        angular.forEach(line['children'], function (child, childId) {
                            angular.forEach(response.data.returnedGridsToUpdate, function (childToUpdate, childIdToUpdate) {
                                if (childIdToUpdate == childId) {
                                    vm.lines[lineKey]['children'][childId] = childToUpdate;
                                }
                            });
                        });
                    }
                });
                // TODO: Rafael Check this later
                let updatedValues = vm.lines;
                vm.lines = [];
                $scope.$apply();
                vm.lines = updatedValues;
                $scope.$apply();
                // TODO: Rafael Check this later

            }

        } catch (exception) {

            switch (exception.constructor.name) {
                case ('ArgumentUndefinedException'):
                    console.log('ArgumentUndefinedException');
                    console.log(exception.message);
                    break;

                case ('MissingScanStationFieldsException'):
                    console.log('MissingScanStationFieldsException');
                    console.log(exception.message);
                    break;

                default:
                    console.log(exception.message);
            }
        }
    }

    /**
     * Method that will connect the Scan Station Fields data.
     * Temp lines reason: https://stackoverflow.com/questions/29849293/ng-repeat-not-updating-on-update-of-array
     * @param scanStation
     */
    vm.loadData = async function () {

        try {

            // loading the scanStationFields for this screen
            if (vm.scanStation != undefined && vm.scanStation.id != undefined && vm.scanStation.scanStationObj != undefined) {
                let response = await GridFactory.getGridLines(vm.scanStation.id);
                vm.lines = [];
                vm.lines = response.data.lines;
                $scope.$apply();
            }

        } catch (exception) {

            switch (exception.constructor.name) {
                case ('ArgumentUndefinedException'):
                    console.log('ArgumentUndefinedException');
                    console.log(exception.message);
                    break;

                case ('MissingScanStationFieldsException'):
                    console.log('MissingScanStationFieldsException');
                    console.log(exception.message);
                    break;

                default:
                    console.log(exception.message);
            }
        }
    }

    /**
     * Method to add a new grid item, can be a full new row, or a child
     * @param scanStation
     * @param gridWidth
     * @param parentId
     */

    vm.addGridItem = async function (scanStation, gridWidth, parentId = null) {
        try {

            await GridFactory.addGridItem(scanStation.id, gridWidth, parentId);
            await vm.loadData();

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method to delete an element
     * @param gridId
     */

    vm.deleteGridItem = async function (gridId, parentId) {
        try {
            await GridFactory.deleteGridItem(gridId);
            await vm.loadData();
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method to move a field to another element
     * @param elementIdToUpdateTo
     * @param fieldId
     */

    vm.changeFieldParentElement = async function (elementIdToUpdateTo, fieldId) {
        try
        {
            let gridIdsToUpdateTo = await GridFactory.changeFieldParentElement(elementIdToUpdateTo, fieldId);
            vm.elementIdToUpdateTo = null;
            $rootScope.$broadcast('reloadSpecificGrids', gridIdsToUpdateTo.data.data);
            $scope.$apply();
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method to move a field up and down within an element
     * @param direction
     * @param fieldId
     */

    vm.reOrderFields = async function (direction, fieldId, gridId) {
        try {
            await GridFactory.reOrderFields(direction, fieldId);
            let gridIdsToUpdate = [gridId];
            await vm.reloadSpecificGrids(gridIdsToUpdate);
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method expanding or retracting the grid size
     * @param gridId
     * @param lineParentId
     * @param originalGridSize
     * @param newGridSize
     */

    vm.editGridSize = async function (gridId, lineParentId, originalGridSize, newGridSize) {
        try {
			
			let lineIndexToUpdate;
			// TODO this needed to be added because we had to reset the array keys for the lines
			angular.forEach(vm.lines, function(line, index)  {
				if(line.template.id == lineParentId) {
					lineIndexToUpdate = index;
				}
			});

            vm.lines[lineIndexToUpdate].template.used_width = vm.lines[lineIndexToUpdate].template.used_width-(originalGridSize-newGridSize);
            await GridFactory.editGridSize(gridId, newGridSize);


        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method to duplicate a field inside a grid
     * @param field

     */

    vm.duplicateStationField = async function (field, gridId) {
        try {
            let reload = true;
            await scanStation.duplicateStationField(field);
            await vm.scanStation.getScanStationData(vm.scanStation.id, 'add-new-field', reload);
            await vm.scanStation.getScanStationRules(vm.scanStation.scanStationObj.id);
            await vm.reloadSpecificGrids([gridId]);

            // TODO: Rafael Check this later
            // await vm.scanStation.updateScanStation('prevent-field-rebuild');
            // $rootScope.$broadcast('reloadSpecificGrids', [gridId]);
            // TODO: Rafael Check this later

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Method to move a row up and down in the bloc
     * @param direction
     * @param gridId
     */

    vm.reOrderGridRow = async function (direction, gridId) {
        try {
            console.log([direction, gridId, vm.scanStation.id])

            await GridFactory.reOrderGridRow(direction, gridId, vm.scanStation.id);
            await vm.loadData();
            $scope.$apply();
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This are the actions to set before add a new field to a grid element.
     * @param key
     */
    vm.addFieldElement = (key) => {
        try
        {
            vm.scanStation.newFieldGridId=key;
            vm.scanStation.showFieldUpdatePopUp = false;
            vm.scanStation.showAddNewField=true;
            vm.fc.initialFieldParams={};
            vm.fc.initialFieldParams.upperLowerCase='mixed-case';
            vm.scanStation.scrollTo('edit-create-fields', '.data-tracking-fields', true);
            vm.fc.showCaseFieldType='text';
            vm.scanStation.showInitialFieldParameters=true;
            vm.scanStation.modalOn();

        } catch (exception) {
            console.log(exception);
        }

    }

    /**
     * This is the edit action of an element.
     * @param key
     */
    vm.editFieldElement = async (key, field) => {
        try
        {
            vm.scanStation.modifyingGridId=key;
            vm.fc.initialFieldParams={};
            await vm.fc.setInitialFieldParametersFromEdit(field);
            vm.scanStation.editFieldDetails(field.id,field.field_name,field.field_slug, field.field_type, field.field_data);
            vm.scanStation.scrollTo('edit-create-fields', '.data-tracking-fields', true);
            vm.fc.showCaseFieldType=field.field_type;
            vm.scanStation.showInitialFieldParameters=true;
            vm.scanStation.modalOn();
            vm.scanStation.showFieldUpdatePopUp = true;
            $scope.$apply();

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will be responsible to load the field rules.
     * @param key
     * @param field
     */
    vm.loadFieldRules = (key, field) => {
        try
        {
            vm.scanStation.modifyingGridId=key;
            vm.scanStation.currentSelectedFieldParameters={};
            vm.scanStation.editFieldRules(field, [field.fieldIndex,field.field_order], 'add-rules');
            // vm.scanStation.resetNewRule();
            vm.scanStation.showParametersInSideBar=false;
            vm.scanStation.showRulesInSideBar=true;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will be responsible to load the field parameters.
     * @param key
     * @param field
     */
    vm.loadFieldParameters = (key, field) => {
        try
        {
            vm.scanStation.modifyingGridId=key;
            vm.scanStation.currentSelectedFieldParameters={};
            vm.scanStation.editFieldRules(field, [field.fieldIndex,field.field_order], null);
            // vm.scanStation.resetNewRule();
            vm.scanStation.showParametersInSideBar=true;
            vm.scanStation.showRulesInSideBar=false



        } catch (exception) {
            console.log(exception);
        }
    }




}

export default GridScanStationController;