import * as angular from 'angular';
import * as echarts from 'echarts/index.blank'
import EChartOption = echarts.EChartsOption;
import $ from "jquery";

"use strict";

angular.module('FieldModule')
    .service('eChartStackedHorizontalBar', eChartStackedHorizontalBar);



eChartStackedHorizontalBar.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function eChartStackedHorizontalBar() {

    let service = this;

    /**
     * method to build the basic line chart
     *
     * @param fieldSlug
     */
    service.build = async function (fieldSlug, eChartData, genericOptions) {
        // based on prepared DOM, initialize echarts instance

        let echartDataSeriesLayout = echarts.init(document.getElementById('echart-' + fieldSlug));
        let seriesArray = [];

        let i = 0;

        angular.forEach(eChartData.data.itemCountResults,function(itemToCountRow, legendKey) {

            let dataArray = [];
            angular.forEach(itemToCountRow, function(countValue) {
                dataArray.push(countValue);
            });
            seriesArray.push( {
                data: dataArray,
                type: 'bar',
                name: legendKey,
                stack: 'stack',
                showSymbol: false,
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
            });

            i++;
        });
        let defaultColour = $('.username-text > p').css("color");
        let options = {
               legend: {
                data: eChartData.data.legendArray
            },

            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            yAxis: [
                {
                    type: 'category',
                    data: eChartData.data.XAxisItems,
                    axisLabel: {
                        color: defaultColour
                    },
                    splitLine: {
                        lineStyle: {
                            opacity: .3
                        }
                    }
                }
            ],
            xAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        color: defaultColour
                    },
                    splitLine: {
                        lineStyle: {
                            opacity: .3
                        }
                    }
                }
            ],
            series: seriesArray
        };

        options = Object.assign(options, genericOptions);


        echartDataSeriesLayout.setOption(options);
    }
}

