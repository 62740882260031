import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('dropdownFromListField', dropdownFromListFieldDirective)
    .directive('dropdownFromListFieldPrint', dropdownFromListFieldPrintDirective)
    .directive('dropdownFromListFieldBuild', dropdownFromListFieldBuild)
    .service('dropdownFromListField', dropdownFromListField);

dropdownFromListFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dropdownFromListFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/dropdown-from-list/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

dropdownFromListFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dropdownFromListFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/dropdown-from-list/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function dropdownFromListField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'dropdownFromList': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please add at least one item to your list',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'defaultValue': {
                    'validation' : {
                        'required': false
                    }
                },
                'applyRulesOnInitiation': {
                    'validation' : {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dropdownFromListFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/dropdown-from-list/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
