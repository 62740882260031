import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('autocompleteField', autocompleteFieldDirective)
    .directive('autocompleteFieldPrint', autocompleteFieldPrintDirective)
    .service('autocompleteField', autocompleteField)
    .directive('autocompleteFieldBuild', autocompleteFieldBuild);

autocompleteFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function autocompleteFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/autocomplete/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

autocompleteFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function autocompleteFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/autocomplete/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function autocompleteField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'dropdownFromStationLookUp': {
                    'subParameters': {
                        'stationId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a Bloc to look up',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'fieldIdToLookUp': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a field to look up',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select a bloc and a field to look up',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'dropDownFromStationExcludeMatchingDataFromAnotherFieldData': {
                    'validation' : {
                        'required': false,
                    }
                },
                'localFieldMatchingFieldPairs': {
                    'validation' : {
                        'required': false,
                    }
                },
                'dropdownFromStationReverseOrder': {
                    'validation' : {
                        'required': false,
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function autocompleteFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/autocomplete/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

