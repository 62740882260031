import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('imageUrlField', ImageUrlFieldDirective)
    .directive('imageUrlFieldPrint', ImageUrlFieldPrintDirective)
    .directive('imageUrlFieldBuild', imageUrlFieldBuild)
    .service('ImageUrlFieldService', ImageUrlFieldService);

ImageUrlFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function ImageUrlFieldDirective(Configuration) {

    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/image-field-url/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

ImageUrlFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function ImageUrlFieldPrintDirective(Configuration) {

    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/image-field-url/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

ImageUrlFieldService.$inject = ['Configuration'];

/**
 * Service constructor.
 * @constructor
 */
function ImageUrlFieldService(Configuration) {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'externalFileLookUp': {
                    'validation' : {
                        'required': false,
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

    let version = service.version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    service.noImage = '/components/field/img/no-image.jpg?v=' + version + 'rand=' + random;

    /**
     * Main validation of this field.
     *
     * @param field
     * @param scanStation
     * @param row
     * @returns {boolean}
     */
    service.validate = function (field, scanStation) {
        if (field.field_type == 'digital-signature' || field.field_type == 'image-url-field' ||
            (scanStation.scanStationObj.stationDetails.show_previews_of_uploaded_images == 1 && field.field_type == 'file')) {
            return true;
        }
        return false;
    }

    /**
     * Validation of image path, so if this is invalid
     * we can add a default missing image.
     *
     * @returns {string}
     */
    service.validateImagePath = function (row, field) {
        let regex = new RegExp(".+\\.(jpg|png|gif|bmp)$");
        let imagePath = row[field.field_slug];
        let imagePathWithoutParams = imagePath.split('?')[0];

        return imagePathWithoutParams.match(regex) ? true : false;
    }

}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function imageUrlFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/image-field-url/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}