import * as angular from 'angular';

'use strict';

angular.module('HelpModeModule')
    .factory('HelpModeFactory', HelpModeFactory)
    .config(HelpModeConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function HelpModeConfiguration($compileProvider, $httpProvider, $provide) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
    // Intercept http calls.
    // Change the token to the Bit Book API token
    $provide.factory('gitBookInterceptor', function ($q) {
        return {
            // On request success
            request: function (config) {
                if(config.url.includes('https://api-beta.gitbook.com')) {
                    config.headers.Authorization = 'Bearer ' + 'bU1HeUprZmJqdWQyMXpBUGRPOXRibHkyMmJ5MjotTVVKVHI3TWt0eWstX2RRREFmci0tTVVKVHI3TkhJV0RNa1JNTGh5cg==';
                }
                // Return the config or wrap it in a promise if blank.
                return config || $q.when(config);
            },

            // On request failure
            requestError: function (rejection) {
                return $q.reject(rejection); // Return the promise rejection.
            },

            // On response success
            response: function (response) {
                return response || $q.when(response); // Return the response or promise.
            },

            // On response failure
            responseError: function (rejection) {
                return $q.reject(rejection); // Return the promise rejection.
            }
        };
    });

    // Add the interceptor to the $httpProvider.
    $httpProvider.interceptors.push('gitBookInterceptor');

}

HelpModeFactory.$inject = ['$http', 'Configuration', '$window', '$auth'];

/**
 * Object that will create a HelpMode Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {HelpModeFactory}
 * @constructor
 */
function HelpModeFactory($http, Configuration, $window, $auth) {

    let factory = this;

    /**
     * Calls the Git Books API and gets the help based on the key
     * @param helpModeKey
     * @returns {*}
     *
     * NB: This function is currently not in use
     */
    factory.getHelpFromGitBooks = function (helpModeKey) {
        /*
        let apiBase = 'https://api-beta.gitbook.com/v1/spaces/-MSr2uF2m-DtSpCxknCK/content/v/[VARIANT]/url/[PAGE URL]';
        let data = {};
        let returnData = $http.get(apiBase, data);

        return returnData;
        */
    }


    return factory;
}
