import * as angular from 'angular';
import * as echarts from 'echarts/index.blank'
import EChartOption = echarts.EChartsOption;
import $ from "jquery";

"use strict";

angular.module('FieldModule')
    .service('eChartGradientStackedArea', eChartGradientStackedArea);



eChartGradientStackedArea.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function eChartGradientStackedArea() {

    let service = this;

    /**
     * method to build the basic line chart
     *
     * @param fieldSlug
     */
    service.build = async function (fieldSlug, eChartData, genericOptions) {
        // based on prepared DOM, initialize echarts instance

        let echartDataSeriesLayout = echarts.init(document.getElementById('echart-' + fieldSlug));
        let seriesArray = [];
        let defaultColour = $('.username-text > p').css("color");
        let coloursArray = [
            [
                'rgba(128, 255, 165)',
                'rgba(1, 191, 236)'
            ],
            [
                'rgba(0, 221, 255)',
                'rgba(77, 119, 255)'
            ],
            [
                'rgba(55, 162, 255)',
                'rgba(116, 21, 219)'
            ],
            [
                'rgba(255, 0, 135)',
                'rgba(135, 0, 157)'
            ],
            [
                'rgba(255, 191, 0)',
                'rgba(224, 62, 76)'
            ],
            [
                'rgba(67, 77, 206)',
                'rgba(22, 27, 91)'
            ],
            [
                'rgba(248, 240, 9)',
                'rgba(73, 71, 3)'
            ],
            [
                'rgba(24, 212, 210)',
                'rgba(8, 61, 60)'
            ],
            [
                'rgba(235, 21, 87)',
                'rgba(111, 12, 42)'
            ],
        ]

        let i = 0;

        angular.forEach(eChartData.data.itemCountResults,function(itemToCountRow, legendKey) {

            let dataArray = [];
            angular.forEach(itemToCountRow, function(countValue) {
                dataArray.push(countValue);
            });
            seriesArray.push( {
                data: dataArray,
                type: 'line',
                name: legendKey,
                stack: 'stack',
                smooth: true,
                lineStyle: {
                    width: 0
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: coloursArray[i][0]
                    }, {
                        offset: 1,
                        color: coloursArray[i][1]
                    }])
                },
                emphasis: {
                    focus: 'series'
                },
            });

            i++;
        });

        let options = {
            color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00', '#434dce', '#f8f009', '#f8f009', '#f8f009'],
            legend: {
                data: eChartData.data.legendArray
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: eChartData.data.XAxisItems,
                    axisLabel: {
                        color: defaultColour
                    },
                    splitLine: {
                        lineStyle: {
                            opacity: .3
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        color: defaultColour
                    },
                    splitLine: {
                        lineStyle: {
                            opacity: .3
                        }
                    }
                }
            ],
            series: seriesArray
        };

        options = Object.assign(options, genericOptions);


        echartDataSeriesLayout.setOption(options);
    }
}

