import * as angular from 'angular';
import FormFactory from "./form.factory";
import $ from "jquery";

"use strict";

angular.module('FormModule')
       .directive('lastField', LastField);

LastField.$inject = [];

function LastField() {

    let submited = false;

    return ({
        restrict: "A",
        link: function(scope, element, iAttrs) {

            // this will trigger only for the last field with last-field="{{last}}"
            if(iAttrs.lastField === 'true'){
                element.bind('keydown', function (e) {
                    let keyCode = e.keyCode || e.which;
                    if (keyCode == 9 || keyCode == 13) {
                        e.preventDefault();
                        if (submited === false) {
                            try {
                                submited = true;
                                return true;
                            } finally {
                                setTimeout(function () {
                                    submited = false;
                                }, 1000);
                            }
                        } else {
                            return false
                        }
                    }

                });
            }
        }
    });
}

export default LastField;